<template>
  <div>
    <AtomLogo />
    <MoleculeCard size="md">
      <MoleculeForm
        :validator="$v"
        :disabled="submitLoading"
        :loading="submitLoading"
        @submit="submitHandler"
      >
        <h2 class="card-title text-center mb-4">{{ $t('pages.password.forgot.title') }}</h2>
        <p class="text-muted mb-4">{{ $t('password.forgot.description') }}</p>
        <AtomInput
          v-model.trim="email"
          label="email"
          class="mb-3"
        />
        <div class="form-footer">
          <AtomButton
            type="submit"
            color="primary"
            class="w-100"
            :disabled="$v.$anyError"
          >
            <mail-icon />
            {{ $t('action.submit') }}
          </AtomButton>
        </div>
      </MoleculeForm>
    </MoleculeCard>
    <div class="text-center text-muted mt-3">
      <i18n path="password.forgot.back">
        <template #action>
          <router-link to="/login">
            {{ $t('action.back_login') }}
          </router-link>
        </template>
      </i18n>
    </div>
    <div class="text-center text-muted mt-3">
      {{ $t('support.need.question') }}
      <a
        :href="$telegramLink"
        target="_blank"
        tabindex="-1"
      >
        {{ $t('support.need.answer') }}
      </a>
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'

import MoleculeCard from '@/components/MoleculeCard'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomButton from '@/components/AtomButton'
import AtomLogo from '@/components/AtomLogo'

export default {
  title: vm => vm.$t('pages.password.forgot.title'),

  components: {
    MoleculeCard,
    MoleculeForm,
    AtomInput,
    AtomButton,
    AtomLogo,
  },

  data: () => ({
    email: '',
    submitLoading: false,
  }),

  validations: {
    email: { email, required },
  },

  methods: {
    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.submitLoading = true
        await this.$store.dispatch('forgotPassword', this.email)
        this.email = ''
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('password.forgot.success.message'),
          type: 'success',
        })
      } catch {} finally {
        this.submitLoading = false
      }
    }
  },
}
</script>
